import { useState } from "react";

import { IconButton, Stack, Tooltip, Box, Typography, Divider } from "@mui/material";
import { MenuBook, StickyNote2 } from "@mui/icons-material";

import Info from "./Info";

function SideBar() {
  const [infoId, setInfoId] = useState("");

  const handleInfoModal = (id) => {
    setInfoId(id);
  };

  return (
    <>
      <Info
        open={Boolean(infoId)}
        title={
          infoId === "1"
            ? "Υπόμνημα"
            : "Οδηγίες Χρήσης"
        }
        setInfoId={setInfoId}
      />
      <Stack
        direction={"column"}
        p={1}
        sx={{
          position: "absolute",
          right: 0,
          top: "30%",
          zIndex: 499,
          backgroundColor: "white",
          borderTopLeftRadius: "15px",
          borderBottomLeftRadius: "15px",
          boxShadow: "2px 2px 4px 2px grey",
        }}
      >
        <IconButton
            size="medium"
            aria-label="close"
            onClick={() => handleInfoModal("1")} 
            disableRipple={true}
            style={{ backgroundColor: 'transparent' }} >
          <Box display={'flex'} width={'100%'} justifyContent={'flex-start'} alignItems={'center'} columnGap={1}>
            <MenuBook />
            <Typography fontWeight={'700'}>Υπόμνημα</Typography>
          </Box>
          </IconButton>
        <Divider />
          <IconButton
            onClick={() => handleInfoModal("2")}
            size="small"
            aria-label="close"
            disableRipple={true}
            style={{ backgroundColor: 'transparent' }}
          >
            <Box display={'flex'} width={'100%'} justifyContent={'flex-start'} alignItems={'center'} columnGap={1}>
            <StickyNote2 />
            <Typography fontWeight={'700'}>Οδηγίες Χρήσης</Typography>
            </Box>
          </IconButton>
      
      </Stack>
    </>
  );
}

export default SideBar;
