import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import villages from "./../assets/villages.json";
import * as turf from "@turf/turf";
import { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";

import { useContext } from "react";
import { LocaleContext } from "./../App";
import NestedMap from "./NestedMap";



function InfoSecond({ children, transcript, clearTranscript }) {
  const [villagesArray, setVillagesArray] = useState([]);
  const [bbox, setBbox] = useState([]);

  const locale = useContext(LocaleContext);

  useEffect(() => {
    if (transcript?.village?.length > 0) {
      let newArr = [];
      for (let j = 0; transcript.village.length > j; j++) {
        for (let i = 0; villages.length > i; i++) {
          if (transcript.village[j] === villages[i]["Χωριο"] && transcript.code === villages[i].id) {
            newArr.push(villages[i]);
          }
        }
      }
      setVillagesArray(newArr);
    }
  }, [transcript]);

  useEffect(() => {
    let multipoint = [];
    if (villagesArray?.length > 0) {
      for (let i = 0; villagesArray.length > i; i++) {
        multipoint.push([villagesArray[i].lon, villagesArray[i].lat]);
      }
      let multiPt = turf.multiPoint(multipoint);
      let bboxPt = turf.bbox(multiPt);
      setBbox(bboxPt);
    }
  }, [villagesArray]);


  return (
    <>
      <IconButton
        onClick={clearTranscript}
        size="small"
        aria-label="close"
        sx={{ position: "absolute", top: "10px", right: "10px" }}
      >
        <ArrowBackIcon />
      </IconButton>
      <Stack
        sx={{
          background: "white",
          width: {
            xs: "100%",
            sm: "100%",
            md: "700px",
            lg: "700px",
            xl: "700px",
          },
          maxHeight: "100%",
          overflowX: "auto",
          overflowY: "hidden",
        }}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          columnGap={2}
          sx={{
            paddingBottom: "10px",
          }}
        >
          <TextSnippetIcon style={{ color: "brown", fontSize: "2rem" }} />
          <Typography sx={{ color: "brown", fontWeight: "bold" }}>
            {transcript.code}
          </Typography>
        </Stack>
        <Stack>
          <Typography sx={{ color: "brown", fontWeight: "bold" }}>
            {locale === "gr" ? transcript.location : transcript.locationEn}
          </Typography>
        </Stack>
        {children}
        {villagesArray.length > 0 && (
          <Stack direction={"column"} rowGap={4}>
            <NestedMap villages={villagesArray} boundingBox={bbox} />
          </Stack>
        )}
      </Stack>
    </>
  );
}

export default InfoSecond;
