import { Divider, Typography, Box } from "@mui/material";
import Stack from "@mui/material/Stack";
import Logo from "./../../src/assets/logo.png";
import LogoAkademia from "./../../src/assets/akadimia.png";

function Header({ getLocale }) {
  const goToLink = () => {
    window.open('http://www.academyofathens.gr/', '_blank', 'noopener,noreferrer')
  }
  return (
    <Stack
      direction={"row"}
      sx={{
        width: "100vw",
        minHeight: "80px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        position: "relative",
        zIndex: 501,
        backgroundColor: '#155e82'
      }}
    >
      <Box display={"flex"} flex={1} px={2} py={1} alignItems={'center'}>
        <Box display={"flex"} sx={{ cursor: 'pointer' }} onClick={goToLink} height={'64px'} alignItems={'center'}>
          <img alt={"akadimia"} height={"100%"} src={LogoAkademia}></img>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          px={3}
          height={'100%'}
          borderLeft={"2px solid black"}
          borderRight={"2px solid black"}
          ml={2}
          flex={4}
        >
          <Typography fontSize={"1.8em"} fontWeight={"bold"} color={"white"}>
            ΧΑΡΤΗΣ ΧΕΙΡΟΓΡΑΦΩΝ ΑΡΧΕΙΟΥ ΚΕΝΔΙ
          </Typography>
        </Box>
        <Box display={"flex"} direction={"column"} pl={1.5} py={1} flex={1}>
          <Typography  color={'white'} noWrap={false}>
            ΚΕΝΤΡΟ ΕΡΕΥΝΗΣ ΝΕΟΕΛΛΗΝΙΚΩΝ ΔΙΑΛΕΚΤΩΝ ΚΑΙ ΙΔΙΩΜΑΤΩΝ - ΙΛΝΕ
          </Typography>
        </Box>
      </Box>
      <Box display={"flex"} alignItems={'center'} bgcolor={'white'}>
        <img alt={"logo"} height={"80px"} src={Logo}></img>
      </Box>
    </Stack>
  );
}

export default Header;
