import {
  Typography,
  Box,
  Dialog,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";

function Info({ open, title, setInfoId }) {
  const handleClose = (e, reason) => {
    e.stopPropagation();
    if (reason === "backdropClick" || !reason) {
      setInfoId("");
    }
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <IconButton
        onClick={handleClose}
        size="small"
        aria-label="close"
        sx={{ position: "absolute", top: "10px", right: "10px" }}
      >
        <Close />
      </IconButton>
      <DialogTitle>{title}</DialogTitle>
      <Box px={3} pb={2}>
        {title === "Υπόμνημα" ? (
          <Typography>
            Ο χάρτης απεικονίζει τα 1552 χειρόγραφα (=συλλογές διαλεκτικού
            υλικού) του Αρχείου του ΚΕΝΔΙ-ΙΛΝΕ που έχουν συλλεγεί από τα τέλη
            του 19 ου αιώνα μέχρι το 2023. Ο χάρτης θα ενημερώνεται περιοδικά
            για τα νέα χειρόγραφα που εντάσσονται κατ’ έτος στο Αρχείο. <br></br> <br></br>Οι
            πληροφορίες που δίνονται για το κάθε χειρόγραφο είναι οι εξής: α)
            τίτλος χειρογράφου, β) συγγραφέας, γ) χρονολογία και δ) αριθμός
            σελίδων.  Ο χάρτης διακρίνει τα χειρόγραφα ανά γεωγραφικές
            κατηγορίες εντός και εκτός Ελλάδος. Έτσι, τα χειρόγραφα που έχουν
            συλλεγεί από διαλεκτικές ποικιλίες που ευρίσκονται εντός των ορίων
            του ελληνικού κράτους κατηγοριοποιούνται με βάση την διοικητική
            διαίρεση της Ελλάδας σε γεωγραφικά διαμερίσματα (π.χ. Θεσσαλία) και
            την υποδιαίρεση σε νομούς (π.χ. νομός Μαγνησίας) που ίσχυε πριν από
            το πρόγραμμα Καλλικράτης (2011). Εξαίρεση αποτελούν τα νησιά, τα
            οποία μπορούν να επιλεγούν ένα-ένα, και η περιοχή της Τσακωνιάς, η
            οποία απεικονίζεται με ειδική κόκκινη κουκκίδα. <br></br> <br></br> Αντίθετα, για τα
            χειρόγραφα που αφορούν διαλεκτικές ποικιλίες που ομιλούνται ή
            ομιλούνταν εκτός Ελλάδος υπάρχουν ειδικές κόκκινες κουκκίδες ανάλογα
            με την περιοχή από την οποία προέρχονται (π.χ. Κάτω Ιταλία, Ίμβρος,
            Καππαδοκία κλπ.). <br></br> <br></br> Όταν ένα χειρόγραφο προέρχεται από συγκεκριμένο
            οικισμό ή οικισμούς, ο χάρτης παρέχει την δυνατότητα γεωαναφοράς σε
            αυτόν/ αυτούς.
          </Typography>
        ) : (
          <Typography>
            Για να δείτε τι υλικό υπάρχει διαθέσιμο στο Αρχείο του ΚΕΝΔΙ-ΙΛΝΕ για
κάποια περιοχή, επιλέξτε την πατώντας επάνω της πάνω στον χάρτη.
Kάνοντας zoom in o χάρτης κατεβαίνει σε χαμηλότερη υποδιαίρεση
(Διαμέρισμα &gt; Νομός). Οι περιοχές εκτός Ελλάδος και η Τσακωνιά
είναι προσβάσιμες από τις κόκκινες κουκκίδες. Πατώντας πάνω σε μια
περιοχή, εμφανίζεται στα δεξιά η καρτέλα με τα διαθέσιμα χειρόγραφα
από αυτήν.
<br></br> <br></br>  Στην συνέχεια, πατήστε πάνω στο χειρόγραφο που επιθυμείτε για να
εμφανιστούν στα δεξιά οι πληροφορίες χειρογράφου (τίτλος,
συγγραφέας, χρονολογία και αριθμός σελίδων) και η γεωαναφορά στον
οικισμό ή τους οικισμούς από τον οποίο προέρχεται.
<br></br> <br></br>  Για να επιστρέψετε από την καρτέλα με τις πληροφορίες ενός
χειρογράφου στην καρτέλα με την λίστα χειρογράφων της ευρύτερης
γεωγραφικής κατηγορίας, πατήστε πάνω στο αριστερόστροφο βελάκι
πάνω δεξιά. Για να κλείσετε την καρτέλα με την λίστα χειρογράφων
πατήστε το ‘Χ’ πάνω δεξιά και κάντε zoom out για να δείτε τον χάρτη
στην πλήρη έκτασή του.
<br></br> <br></br>  Στην αρχική σελίδα, η μπάρα ‘Εύρεση τοποθεσιών’ πάνω δεξιά
παρέχει την δυνατότητα να αναζητήσετε την περιοχή που σας
ενδιαφέρει. Π.χ. αν πληκτρολογήσετε ‘Σκύρος’, ο χάρτης θα σας
ανακατευθύνει στην περιοχή της Σκύρου και πατώντας πάνω στο νησί
θα εμφανιστεί η καρτέλα με τα διαθέσιμα χειρόγραφα από εκεί.
          </Typography>
        )}
      </Box>
    </Dialog>
  );
}

export default Info;
